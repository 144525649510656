/* Import Museo font from Adobe */
@import url("https://use.typekit.net/pmx2coz.css");

/* Css reset  */
/*
Josh's Custom CSS Reset
https://www.joshwcomeau.com/css/custom-css-reset/
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html,
body,
#root {
  height: 100%;
}

body {
  line-height: var(--root-line-height);
  /* line-height: calc(1em + 0.65rem); */
  /* line-height: calc(2px + 2ex + 2px); */
  -webkit-font-smoothing: antialiased;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

/* This ensures that long words or URLs won't overflow their container but won' t break in the middle unless necessary. The hyphens: auto; */
/* property will add hyphens when necessary to break long words. */
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
  hyphens: auto;
}

#root {
  isolation: isolate;
}

/* Others uusefull settings */

body {
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  background: var(--root-background-color);
}

p {
  max-width: 50ch;
}

button,
fieldset,
input {
  all: unset;
}

/* svg { */
/*   filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4)); */
/* } */

input {
  box-sizing: border-box;
}

:root {
  --system-ui: system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  /* Colors */
  --root-color-default: #423c3c;
  --root-color-default-rgb: 66, 60, 60;
  --veryperiColor: #6667ab;
  /* rgb(102, 103, 171); */
  --veryperiColorRgb: 102, 103, 171;
  --salmonColor: #ea27c2;
  --salmonColorRgb: 234, 39, 194;
  /* --greenColor: #70ae0d; */
  --greenColor: #289dbe;
  --greenColorRgb: 40, 157, 190;

  --primaryColor: var(--veryperiColor);
  --secondaryColor: var(--salmonColor);
  --arrowsColor: #656160;

  /* Scrollbar colors */
  --scrollbarBG: #cfd8dc;
  --thumbBG: var(--secondaryColor);

  /* Backgrounds */
  --root-background-color: var(--veryperiColor);
  --root-background-color-default: #423c3c;
  --root-background-color-semi-white: rgba(255, 255, 255, 0.7);

  --root-background-color-header-desktop: #efeae4;
  --root-background-color-header-mobile: #fff;

  --root-background-color-footer: #f9f9f9;
  --root-background-color-white-1: #f2ebe5;
  --root-background-color-white-1-rgb: 242, 235, 229;
  --root-background-color-white-light-brown: #ddccb8;
  --root-background-episode-info-card: var(--root-background-color-white-1);
  --root-background-episode-green-info-card: var(--root-background-episode-info-card);

  /* Fonts */
  --root-line-height: 1.3;
  --root-font-family: museo, var(--system-ui);
  --root-font-weight-regular: 300;
  --root-font-weight-light: 100;
  --root-font-weight-bold: 700;

  /* vwSizes  calc: calc(<pxelsSize> / 19.2 * 1vw )*/
  --screenFactor: 19.2;

  --1Px: calc(1 / 19.2 * 1vw);
  --2Px: calc(var(--1Px) * 2);
  --5Px: calc(var(--1Px) * 5);
  --10Px: calc(var(--1Px) * 10);
  --15Px: calc(var(--1Px) * 15);
  --20Px: calc(var(--1Px) * 20);
  --25Px: calc(var(--1Px) * 25);
  --30Px: calc(var(--1Px) * 30);
  --35Px: calc(var(--1Px) * 35);

  --1MPx: max(1px, calc(1 / 19.2 * 1vw));
  --2MPx: calc(var(--1MPx) * 2);
  --5MPx: calc(var(--1MPx) * 5);
  --10MPx: calc(var(--1MPx) * 10);
  --15MPx: calc(var(--1MPx) * 15);
  --20MPx: calc(var(--1MPx) * 20);
  --25MPx: calc(var(--1MPx) * 25);
  --30MPx: calc(var(--1MPx) * 30);
  --35MPx: calc(var(--1MPx) * 35);


  --root-horizontal-space-0: 0.416666667vw;
  /* 8px */
  --root-horizontal-space-1: 0.833333333vw;
  /* 16px */
  --root-horizontal-space-2: 1.40625vw;
  /* 27px */
  --root-horizontal-space-2-plus: 2.5vw;
  /* 28px */
  --root-horizontal-space-3: 3.854166667vw;
  /* 74px */

  --root-padding-swiper-mobile: 2vw;

  /* This is only a reference. Values are in config.js */
  --root-gap-swipe-base: 10px --root-gap-swipe-mobile: 20px;

  --root-vertical-space-0-rem: 0.5rem;
  /* 8px */
  --root-vertical-space-1-rem: 1.375rem;
  /* 22px */
  --root-vertical-space-2-rem: 2rem;
  /* 32px */
  --root-vertical-space-big-rem: 11.375rem;

  --root-vertical-space-0: 0.833333333vw;
  /* 16px or 1rem  */
  --root-vertical-space-1: 1.145833333vw;
  /* 22px / 19.2 or  */
  --root-vertical-space-2: 1.875vw;
  /* 36px or 2.25rem */
  --root-vertical-space-3: 2.5vw;
  /* 48px or 3rem*/

  --root-vertical-line-separator-size: max(2px, 0.15625vw);
  --root-horizon-line-separator-size: var(--root-vertical-line-separator-size);
  --root-border-size: var(--root-horizon-line-separator-size);
  --root-entrance-exit-to-from: max(10.416666667vw, 200px);

  /* TODO:  <use component> */
  /* Tostify */
  --toastify-color-progress-light: linear-gradient(to right,
      var(--secondaryColor),
      var(--primaryColor));

  /* Border radius size: */
  --root-border-radius-00: max(0.208333333vw, 4px);
  --root-border-radius-0: max(0.5vw, 9.6px);
  --root-border-radius-1: max(0.8vw, 15.36px);
  --root-border-radius-2: max(1.041666667vw, 20px);
  --root-border-radius-3: max(1.302083333vw, 25px);
  --root-border-radius-large: 28px;

  /* Rows */
  /* Note that this value is flexible because rows with thumbs are not upperbounded */
  --root-inline-header-width: 10vw;
  --root-icon-green-row-header-width: min(30.833333333vw, 592px);
  --root-inline-header-width-with-limit: clamp(440px, 20vw, 400px);
  --root-inline-offset-videos-by-category-home: 1vw;

  /* Navigation */
  --root-navigation-height: max(6.5vw, 40px);
  --root-available-viewport: calc(100vh - var(--root-navigation-height));
  --root-min-height-people-row: calc(var(--root-utrender-card-height) * 1.08);
  --root-min-height-people-vertical-column: calc(100% - var(--root-top-utrenders-icon-height));
  --root-min-height-video-row: calc(var(--root-video-card-dimension-width) * 9 / 16 * 1.20);
  --root-min-height-video-story-row: calc(var(--root-video-card-story-width) * 3 / 2 * 1.14);
  --root-min-height-video-slanted-row: calc(var(--root-video-card-slanted-height) * 1.14);

  /* Navigation Green */
  --root-navigation-green-height: max(4.166666667vw, 30px);
  --root-footer-height: calc(var(--root-navigation-height) * 1.666);

  /* used for utrenders card most of the time */
  --root-top-utrenders-icon-width: 5.208333333vw;
  --root-top-utrenders-icon-height: var(--root-top-utrenders-icon-width);
  --root-icon-utrender-width: max(80px,
      8.333333333vw);
  /* middle size is 160 / 19.2 that is 1920px widht the reference in the layout*/
  --root-icon-utrender-height: var(--root-icon-utrender-width);

  /* used in episode utrender on right of the video player */
  --root-icon-utrender-width-small: 5.208333333vw;
  --root-icon-utrender-height-small: var(--root-icon-utrender-width-small);

  --root-icon-utrender-width-small-unbound: min(6.25vw);
  --root-icon-utrender-height-small-unbound: var(--root-icon-utrender-width-small-unbound);

  --root-utrender-card-width: calc(var(--root-icon-utrender-width) * 1.41);
  --root-utrender-card-height: calc(var(--root-utrender-card-width) * 1.19);

  --root-utrender-card-green-width: calc(var(--root-utrender-card-width) - 1.8rem);
  --root-utrender-card-green-height: var(--root-utrender-card-height);

  /* Utrender card in program page on the right of the video player*/
  --root-utrender-card-width-bis: 7.3125vw;
  --root-utrender-card-height-bis: 8.53125vw;

  /* --root-utrender-card-large-width: 375px; */
  --root-utrender-card-large-width: 19.53125vw;
  --root-utrender-card-large-height: calc(var(--root-utrender-card-large-width) / 3 * 4);

  /* Used Category most of the times */
  --root-icon-category-height: max(71px, 7.65625vw);
  --root-icon-category-width: var(--root-icon-category-height);

  /* Used in Category page */
  --root-icon-category-height-big: 10.208333333vw;
  --root-icon-category-width-big: var(--root-icon-category-height-big);

  --root-top-offset-video: 2.3vw;

  --root-icon-category-height-small: clamp(calc(1.6rem * 0.9),
      calc(9vw * 0.9),
      calc(3rem * 0.9));
  --root-icon-category-width-small: var(--root-icon-category-height-small);

  --root-category-swiper-removed-margin: -4.947916667vw;

  /* Swiper home category */
  /* Many tricks and calculations here.. use params to adjust */
  --root-icon-category-vertical-margin: 1.458333333vw;
  --root-icon-container-total-width: calc(var(--root-icon-category-height) + (var(--root-icon-category-vertical-margin) * 2));

  --root-icons-menubar: max(3.541666667vw, 33px);
  --root-icons-stats-width: 3.958333333vw;
  --root-icons-stats-height: var(--root-icons-stats-width);
  --root-icons-stats-width-bigger: 5vw;
  --root-icons-stats-height-bigger: var(--root-icons-stats-width-bigger);

  --root-radix-icon-size: max(15px, 0.78125vw);

  /* Cards */
  --root-episode-card-width: max(250px, 23.125vw);
  /* a 1920px  circa 444px */

  --root-video-card-story-width: max(15.75vw, 150px);
  --root-video-card-story-width-big: 18vw;

  --root-video-card-slanted-width: max(120px, 14.0625vw);
  --root-video-card-slanted-height: max(171px, 20.104166667vw);

  --root-video-card-in-grid: calc(var(--root-video-card-dimension-width) * 1.067);

  --root-hover-card-container-width: max(400px, 26.041666667vw);

  /* TODO:  adjust */
  /* Leads */

  --root-video-player-width: 83.9583333vw;
  /* Program page and affini */

  --root-video-lead-dimension-width: 73.6vw;
  --root-video-lead-dimension-height: calc((var(--root-video-lead-dimension-width) / 16) * 9);

  /*--root-lead-height: var(--root-video-lead-dimension-height);*/
  --root-lead-height: calc(var(--root-video-lead-dimension-height) * 1.12);
  --root-lead-top-utrenders-width: min(26.4vw, 507px);

  /* Swipers */
  /* If you do not customize arrows crawewating custom nodes, swiper arrows
  * (navigation: true) use "--swiper-navigation-color" if it does not
  * exist uses "--swiper-theme-color" */
  --swiper-theme-color: var(--secondaryColor);
  /* Navigation */
  --swiper-navigation-size: max(20px, 2.604166667vw) !important;
  /* Pagination */
  --swiper-pagination-color: white;
  --swiper-pagination-bullet-size: 1.302083333vw;
  --swiper-pagination-bullet-inactive-color: white;
  --swiper-pagination-bullet-inactive-opacity: 0.6;

  --swiper-horizontal-padding-arrow: 1.666666667vw;
  --swiper-vertical-padding-arrow: 5vw;

  --swiper-home-offset: calc(var(--root-inline-header-width) + calc(var(--root-inline-offset-videos-by-category-home) / 4));
  --swiper-move-arrow-left-by-cat-home: calc(var(--swiper-home-offset, 1vw) - var(--swiper-horizontal-padding-arrow) / 2);

  /* Fontssize */
  --root-font-size: 0.875rem;
  /* 16px
  /* --root-font-size: min(0.833333333vw, 16px); */
  /* --root-ratio: 1.333; */
  /* Calculate values */
  --root-font-size-header-mobile: 1.375rem;

  --mini-p-size: max(12px, 0.833333333vw);
  --p-size: max(var(--root-font-size), 0.833333333vw);
  /* 16 / 19.2 */
  /* 16px */

  /* --h6-size: clamp(var(--root-font-size), 0.9375vw, 1.125rem); /* 18px */
  /* --h5-size: clamp(var(--root-font-size), 1.041666667vw, 1.25rem); /* 20px */
  /* --h4-size: clamp(var(--root-font-size), 1.145833333vw, 1.375rem); /* 22px */
  /* --h3-size: clamp(var(--root-font-size), 1.458333333vw, 1.75rem); /* 28px */
  /* --h3-plus-size: clamp(var(--root-font-size), 1.614583333vw, 1.9375rem); /* 31px */
  /* --h2-size: clamp(var(--root-font-size), 1.875vw, 2.25rem); /* 36px */
  /* --h1-size: clamp(1rem, 2.5vw, 3rem); /* 48px */
  /* --h0-size: clamp(var(--root-font-size), 3.3325vw, 3.999rem); /* 63,984px */

  /* Extra levels */
  /* TODO: armonizzali cfr. https://zellwk.com/blog/changing-modular-scale/ */
  --h6-size: max(var(--root-font-size), 0.9375vw);
  /* 18px */
  --h5-size: max(var(--root-font-size), 1.041666667vw);
  /* 20px */
  --h4-size: max(var(--root-font-size), 1.145833333vw);
  /* 22px */
  --h3-size: max(var(--root-font-size), 1.458333333vw);
  /* 28px */
  --h3-plus-size: max(var(--root-font-size), 1.666666667vw);
  /* 32px */
  --h2-size: max(var(--root-font-size), 1.875vw);
  /* 36px */
  --h1-size: max(1rem, 2.5vw);
  /* 48px */
  --h0-size: max(var(--root-font-size), 3.3325vw);
  /* 63,984px */

  /* media-queries limits. We cannot use as css variables. It's just a remainder*/
  /* these values are replicated in config.js for react internal use */
  /*
  --mq-is-mobile-max-width: 767px;
  --mq-is-tablet-min-width: 768px;
  --mq-is-desktop-min-width: 1024px;
  */

  --root-row-margin: 2px;
}

/* Video Lead home  */
@media screen and (max-width: 767px) {
  :root {
    --root-video-lead-dimension-width: 96vw;
    /*--root-video-lead-dimension-height: auto;*/
  }
}

/* video card width */

@media screen and (max-width: 499px) {
  :root {
    --root-gap-swipe-base: 10px;
    --root-padding-swiper-mobile: 2vw;
    --lost-space-from-padding: calc((var(--root-gap-swipe-base) + (var(--root-padding-swiper-mobile) * 2)));
    --root-video-card-dimension-width: calc(50vw - (var(--lost-space-from-padding) * 1 / 2));
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {
  :root {
    --root-gap-swipe-base: 10px;
    --root-padding-swiper-mobile: 10px;
    --number-of-gaps: 2;
    --number-of-cards: 3;
    --lost-space-from-padding: calc(((var(--root-gap-swipe-base) * var(--number-of-gaps)) + (var(--root-padding-swiper-mobile) * 2)));
    --root-video-card-dimension-width: calc(33.333333333vw - (var(--lost-space-from-padding) / var(--number-of-cards)));
  }
}

@media screen and (min-width: 768px) {
  :root {
    --root-row-margin: var(--root-vertical-space-1);
  }
}

/* from here we have to consider scroller width*/
@media screen and (min-width: 768px) and (max-width: 1023px) {
  :root {
    --root-gap-swipe-base: 10px;
    --root-padding-swiper-mobile: 10px;
    --number-of-gaps: 4;
    --number-of-cards: 4;
    --lost-space-from-padding: calc(((var(--root-gap-swipe-base) * var(--number-of-gaps)) + (var(--root-padding-swiper-mobile))));
    --root-video-card-dimension-width: calc(25vw - (var(--lost-space-from-padding) / var(--number-of-cards)));
    --root-lead-height: calc(var(--root-video-lead-dimension-height) * 1.12);
  }
}

@media screen and (min-width: 1024px) {
  :root {
    --scroolbar-width: 8px;
    --inline-header-and-offset-width: 11vw;
    --root-gap-swipe-base: 10px;
    --root-padding-swiper-mobile: 10px;
    --number-of-gaps: 3;
    --number-of-cards: 4;
    --lost-space-from-padding: calc(var(--scroolbar-width) + ((var(--inline-header-and-offset-width) + var(--root-gap-swipe-base) * var(--number-of-gaps)) + var(--root-padding-swiper-mobile)));
    --root-video-card-dimension-width: calc(25vw - (var(--lost-space-from-padding) / var(--number-of-cards)));
    --root-lead-height: calc(var(--root-video-lead-dimension-height) * 1.12);
  }
}

body {
  color: var(--root-color-default);
  font-family: var(--root-font-family);
  font-weight: var(--root-main-font-weight-regular);
  /* moz-scroo */
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  scrollbar-width: thin;
  text-align: center;
}

p {
  font-size: var(--font-size, var(--p-size));
}

h6 {
  font-size: var(--font-size, var(--h6-size));
}

h5 {
  font-size: var(--font-size, var(--h5-size));
}

h4 {
  font-size: var(--font-size, var(--h4-size));
}

h3 {
  font-size: var(--font-size, var(--h3-size));
}

h2 {
  font-size: var(--font-size, var(--h2-size));
}

h1 {
  font-size: var(--font-size, var(--h1-size));
}

/* We need this since webkit browsers do not take into account scrollbar when using calculating vw units. */
/* html { overflow-x: hidden } */

/* Some scrollbar style fro chrome like browsers */
body::-webkit-scrollbar {
  width: 11px;
}

body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}
